import { useNavigate } from "react-router-dom";
import Modal from "../../library/Modal/Modal"
import { useState } from "react";
import { Button } from "react-bootstrap";

const Cuisine = () => {
    const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate()

    const handleClick = () => {
        setOpenModal(true)
    }
    const handleCancel = () => {
        setOpenModal(false)
        navigate(-1)
    }

    return (
        <>
            <Modal clickAway={false} open={openModal} setOpen={setOpenModal}>
                <div className="max-w-[450px]">
                    <p className="font-bold">
                        souhaitez vous sauvegarder
                    </p>
                    <div>
                        <Button variant="outline-primary" size="sm" onClick={handleCancel}>ok</Button>
                        <Button className="mx-2" variant="outline-danger" size="sm" onClick={handleCancel}>Annuler</Button>
                    </div>
                </div>

            </Modal>
            <div>
                <p onClick={handleClick}>Retour</p>
                <h1>Cuisine</h1>


            </div>
        </>

    );
}

export default Cuisine;