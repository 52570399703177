import React from 'react'

import './Produit.css';

const Produit = () => {
  return (
    <div className='m-4'>
             <h3 className='text-danger'>Produit</h3>
        </div>
  )
}

export default Produit