import authHeader from "../../auth-header/auth-header"
import APIInstance from "../../axios/AxiosInstance"
import { endpoints } from "../../endpoints/endpoints"


export const getMyChoixCode = async () => {
   try {
      const choixCode = await APIInstance.get(endpoints.choixCode.get, { headers: authHeader() })
      if (choixCode.data) {
         return choixCode.data
      }
   } catch (error) {
      throw error
   }
}

export const updateChoixCode = async (data) => {
   try {
      const url = endpoints.choixCode.put + data.id;
      const updateCode = await APIInstance.put(url, data, { headers: authHeader() });
      if (updateCode.data) {
         return updateCode.data;
      }
   } catch (error) {
      throw error;
   }

}