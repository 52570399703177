import authHeader from "../auth-header/auth-header";
import  APIInstance from "../axios/AxiosInstance";
import { endpoints } from "../endpoints/endpoints";

export  const getSalles = async(data) => {
    try {
        const salles = await APIInstance.post(endpoints.salle.gets,data, { headers: authHeader() });
        return salles.data;
    } catch (error) {
        throw error;
    }
}