import React from 'react';
import { NavLink } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import Sary from '../../image/404.svg';
import './NotFound.css';

const NotFound = () => {
  return (
    <div className='content'>
      <div className='image'>
        <Image src={Sary} width={500}/>
      </div>
      <div className='soratra'>
        <p>Sorry, the page you are looking for does not exist.</p> 
      </div>
      <div className='bouton'>
          <NavLink to="/menu" id='nav'>Go to Menu</NavLink>
      </div>
    </div>
    
  );
};

export default NotFound;