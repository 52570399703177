import React from 'react'
import ReactModal from "react-modal";

const customStyles = {
   content: {
      top: "10%",
      border: "1px solid #2222",
      borderRadius: "5px",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "none !important",
   },
};

const Modal = ({ open,
   setOpen,
   children,
   clickAway = true }) => {
   const close = () => {
      if (clickAway) {
         setOpen(false);
      }
   };
   return (

         <ReactModal isOpen={open} onRequestClose={close} style={customStyles}> {children}</ReactModal>
   )
}

export default Modal