import React, { useEffect, useState } from 'react';
import { getSalles } from '../../../api/salle/getSalles';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { AiOutlinePlus } from 'react-icons/ai';
import SalleForm from './SalleForm';
import Spinner from 'react-bootstrap/Spinner';
import './listeSalle.css';
import LoadingSpinner from '../../../library/Spinner/LoadingSpinner';

const ListeSalle = () => {
    const [listeSalle, setListeSalle] = useState([]);
    const [visibleModal, setVisibleModal] = useState(false);
    const [idSalle, setIdSalle] = useState(0);
    const [loading, setLoading] = useState(false);

    const fetchListeSalle = async () => {
        try {
            const user_data = localStorage.getItem("user_data");
            const data = JSON.parse(user_data);
            const id_entreprise = data.utilisateur.id_entreprise;
            const liste = await getSalles({ id_entreprise: id_entreprise });
            setListeSalle(liste);
            setLoading(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const showModal = () => {
        setVisibleModal(true);
    };
    const hideModal = () => {
        setVisibleModal(false);
    };

    const openModal = (id) => {
        showModal();
        setIdSalle(id);
    }


    useEffect(() => {
        // const timeout = setTimeout(() => {
            fetchListeSalle();
        // }, 2000); //
    //}, [listeSalle,loading]);
    },[]);

    return (
        <div div className='m-4'>
            <h3 className='text-warning'>Salle</h3>
                {loading ? (
                    <>
                    <Stack direction="horizontal" gap={3}>
                <div className="p-2"></div>
                <div className="p-2 ms-auto"></div>
                <div className="p-2">
                    <Button variant="warning" onClick={() => {
                        openModal(0);
                    }}><AiOutlinePlus /> Ajouter</Button>
                </div>
            </Stack>

            <div>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Nom Salle</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {listeSalle.map(salle => (
                            <tr ey={salle.id}>
                                <td>{salle.id}</td>
                                <td>{salle.nom}</td>
                                <td>
                                    <Stack direction="horizontal" gap={3}>
                                        <Button variant="outline-light" size="sm" style={{ color: 'black' }} onClick={() => {
                                            openModal(salle.id);
                                        }}
                                        >
                                            Modifier
                                        </Button>
                                        <Button variant="outline-warning" size="sm">Détail</Button>
                                    </Stack>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </Table>
            </div>
            </>
                ) : <LoadingSpinner/>}
            
            <SalleForm
                openModal={visibleModal}
                closeModal={hideModal}
                sizeModal="md"
                salleId={idSalle}
            />

        </div>
    );

}


export default ListeSalle;