import { RiAdminLine } from "react-icons/ri"
import { AiFillBank, AiOutlineFieldNumber } from "react-icons/ai"
import { FaHospitalUser } from "react-icons/fa"
import { MdProductionQuantityLimits, MdOutlineTableRestaurant } from "react-icons/md"
import { LuUsers } from 'react-icons/lu'
import { CgShutterstock } from "react-icons/cg"
export const parametreLink = [
   {
      id: 1,
      title: "Company",
      link: '/parametre',
      icon: <AiFillBank />
   },
   {
      id: 2,
      title: "Clients",
      link: '/parametre/client',
      icon: <LuUsers />
   },
   {
      id: 3,
      title: "Fournisseurs",
      link: '/parametre/fournisseur',
      icon: <FaHospitalUser />
   },
   {
      id: 4,
      title: "Produits",
      link: '/parametre/product',
      icon: <MdProductionQuantityLimits />
   },
   {
      id: 5,
      title: "Stocks",
      link: '/parametre/stock',
      icon: <CgShutterstock />
   },
   {
      id: 6,
      title: "Administration",
      link: '/parametre/admin',
      icon: <RiAdminLine />,
      submenu: [
         {
            title: 'Numérotation',
            link: '/parametre/numerotation',
            icon: <AiOutlineFieldNumber />
         },
         {
            title: 'Paramètres financiers',
            link: '/parametre/financier',
            icon: <AiOutlineFieldNumber />
         },
      ],
   },
   {
      id: 7,
      title: "Salle",
      link: '/parametre/salle',
      icon: <MdOutlineTableRestaurant />
   },

]