import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { getMyChoixCode, updateChoixCode } from '../../../../../api/numerotation/choixCode/choixCode'
import LoadingSpinner from '../../../../../library/Spinner/LoadingSpinner'

const ChoixCode = () => {
   const [loading, setLoading] = useState(false)
   const [isUpdate, setIsUpdate] = useState(false);
   const [values, setValues] = useState({
      id: '',
      auto_produit: false,
      auto_client: false,
      auto_article: false
   })
   const handleModify = async () => {
      try {
         setLoading(true)
         const update = await updateChoixCode(values)
         setLoading(false)
         setIsUpdate(false)
      } catch (error) {
         console.log(error)
      }
   }
   useEffect(() => {
      choixCodeApi()
   }, [])

   const choixCodeApi = async () => {
      try {
         setLoading(true)
         const data = await getMyChoixCode()
         setValues(data)
         setLoading(false)
      } catch (error) {
         console.log(error)
      }
   }
   const handleChange = (e) => {
      const { name, checked } = e.target;
      setValues((prev) => ({
         ...prev,
         [name]: checked
      }))
      setIsUpdate(true)
   }
   return (
      <>
         <Form className='d-flex justify-content-between'>
            <Form.Check
               type="switch"
               reverse
               label="Articles"
               name="auto_article"
               onChange={handleChange}
               value={values.auto_article}
            />
            <Form.Check
               type="switch"
               reverse
               label="Clients"
               name="auto_client"
               onChange={handleChange}
               value={values.auto_client}
            />
            <Form.Check
               type="switch"
               reverse
               label="Fournisseurs"
               name="auto_produit"
               onChange={handleChange}
               value={values.auto_produit}
            />
         </Form>
         <div className='my-2 d-flex justify-content-end'>
            <Button variant="outline-info " size="sm" onClick={handleModify} disabled={!isUpdate}>
            {loading ? <LoadingSpinner/> : "" } Enregistrer</Button>
         </div>
      </>
   )
}

export default ChoixCode