import {RiDashboardFill} from "react-icons/ri"
import { AiFillSetting } from "react-icons/ai"
import { FiUsers } from "react-icons/fi"
import { BsWindowDash } from "react-icons/bs"
import { BiRestaurant } from "react-icons/bi"
import { LuListStart, LuFolderCog } from "react-icons/lu"

export const data = [
   {
      id: 1,
      title: 'demarer',
      link: "/demarer",
      icon: <LuListStart /> 
   },
   {
      id: 2,
      title: 'cloturer',
      link: '/cloturer',
      icon: <BsWindowDash /> 
   },
   {
      id: 3,
      title: 'dashboard',
      link: '/dashboard',
      icon: <RiDashboardFill /> 
   },
   {
      id: 4,
      title: 'parametres',
      link: '/parametre',
      icon: <AiFillSetting /> 
   },
   {
      id: 5,
      title: 'compte',
      link: '/mon-compte',
      icon: <FiUsers /> 
   },
   {
      id: 6,
      title: 'cuisine',
      link: '/cuisine',
      icon: <BiRestaurant /> 
   },
   {
      id: 7,
      title: 'classique',
      link: '/classique',
      icon: <LuFolderCog /> 
   },
   {
      id: 8,
      title: 'Reservation',
      link: '/reservation',
      icon: <LuFolderCog /> 
   },
]