import { Container } from "react-bootstrap";
import { useStateContext } from "../../context/user/ContextProvider";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/sidebar";
import { parametreLink } from "../utils/paramatreLink";
import { AiOutlineMenu } from "react-icons/ai";


const Parametre = ({ children }) => {
    const { activeMenu, setActiveMenu } = useStateContext()
    return (
        <div>
            {activeMenu ? (
                <div className="d-flex">
                    <div className='app-sidebars'>
                        <Sidebar titre="Paramètre" link={parametreLink} />
                    </div>
                    <div className='app-sidebar'>
                        <Navbar />
                        {children}
                    </div>
                </div>
            ) : (
                <div>
                    <AiOutlineMenu onClick={() => setActiveMenu((prevMenu) => !prevMenu)} className="icon-app" />
                    <Container>  
                        {children}
                    </Container>
                </div>
            )}
        </div>

    );
}

export default Parametre;