import authHeader from "../auth-header/auth-header";
import  APIInstance from "../axios/AxiosInstance";
import { endpoints } from "../endpoints/endpoints";

export const getmycompany = async() => {
    try {
        const company = await APIInstance.get(endpoints.company.get, { headers: authHeader() });
        if(company.data) {
            return company.data;
        }
    } catch (error) {
        throw error;
    }
}

export const modifymycompany = async(data) => {
    try {
        const company = await APIInstance.put(endpoints.company.put + data.id,data, { headers: authHeader() });
        if(company.data) {
            return company.data;
        }
    } catch (error) {
        throw error;
    }
}