import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import './calc.css';
import Calculatrice from "../../components/Calculatrice/calculatrice"

const ButtonCacl = () => {
    const [show, setShow] = useState(false);
    const handleClick = () => {
        setShow(false)
    }
    return (
        <div>
            <ToastContainer
                className="p-3"
                position="bottom-end"
                style={{ zIndex: 1 }}
            >
                <Toast onClose={() => setShow(false)} show={show} delay={false}>
                    <div className="calcs">
                        <div className="btn-calc">
                            <Button className="calculatrice-btn text-white" variant="outline-dark" onClick={handleClick}>X</Button>
                            <Calculatrice />
                        </div>
                    </div>
                </Toast>
            </ToastContainer>

            <div style={{ position: 'fixed', bottom: 10, right: 10 }}>
                <Button variant="dark" onClick={() => setShow(true)}>Calculatrice</Button>
            </div>
        </div>

    );
}

export default ButtonCacl;