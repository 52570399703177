import authHeader from "../auth-header/auth-header";
import  APIInstance from "../axios/AxiosInstance";
import { endpoints } from "../endpoints/endpoints";

export const createSalle = async(data) => {
    try {
        const newSalle = await APIInstance.post(endpoints.salle.create, data, { headers: authHeader() });
        if(newSalle.data){
            return newSalle.data;
        }
    } catch (error) {
        return error.response.data;
    }
}