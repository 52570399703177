import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Login from "./components/login/login.js";
import Company from './components/parametre/Form/Company/Company';
import Menu from './components/Menu/Menu';
import { Routes, Route } from "react-router-dom";
import User from './components/parametre/Form/User/User';
import Fournisseur from './components/parametre/Form/Fournisseur/Fournisseur';
import Produit from './components/parametre/Form/Produit/Produit';
import Stock from './components/parametre/Form/Stock/Stock';
import Parametre from './components/parametre/Parametre';
import Demarrer from './components/Demarer/Demarer';
import Borne from './components/Demarer/Borne/Borne';
import Cloturer from './components/Cloturer/cloturer';
import Dashboard from './components/Dashboard/dashboard';
import Cuisine from './components/Cuisine/cuisine';
import RequireAuth from "./context/user/RequireAuth"
import ButtonCacl from './library/Button/Button_cacl';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import NotFound from './components/NotFound/NotFound';
import Reservation from './components/Reservation/Reservation';
import Numerotation from './components/parametre/Form/Numerotaion/Numerotation';
import ListeSalle from './components/parametre/Salle/ListeSalle';
import Financier from './components/parametre/Form/Financier/Financier';

function App() {
  //console.log(JSON.parse(localStorage.getItem('user_data')).utilisateur.id_type_utilisateur);
  const users = localStorage.getItem('user_data');
  return (
    <I18nextProvider i18n={i18n}>
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<RequireAuth />} >
          <Route path="/menu" element={<Menu />} />
          <Route path="/cloturer" element={<Cloturer />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/cuisine" element={<Cuisine />} />
          <Route path="/reservation" element={<Reservation />} />
          {/* Routes parametres */}
          <Route path="/parametre">
            <Route index element={<Parametre><Company /></Parametre>} />
            <Route path="client" element={<Parametre><User /></Parametre>} />
            <Route path="fournisseur" element={<Parametre><Fournisseur /></Parametre>} />
            <Route path="salle" element={<Parametre><ListeSalle /></Parametre>} />
            <Route path="product" element={<Parametre><Produit /></Parametre>} />
            <Route path="stock" element={<Parametre><Stock /></Parametre>} />
            <Route path="numerotation" element={<Parametre><Numerotation /></Parametre>} />
            <Route path="financier" element={<Parametre><Financier /></Parametre>} />
            
          </Route>
         
          {/* Routes parametres */}

          {/* Routes Demarer */}
          <Route path="/demarer">
            <Route index element={<Demarrer></Demarrer>} />
            <Route path='borne' element={<Demarrer><Borne /></Demarrer>} />
          </Route>
          <Route path='*' element={<NotFound/>}/>
         
        </Route>
        {/* Routes Demarer */}
      </Routes>
      {
            users ?  < ButtonCacl /> : ""
      }
    </div>
    </I18nextProvider>
  );
}

export default App;
