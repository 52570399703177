import authHeader from "../auth-header/auth-header";
import  APIInstance from "../axios/AxiosInstance";
import { endpoints } from "../endpoints/endpoints";


/*-------Paramètre financier-------*/
export const getMyFinance = async() => {
    try {
        const financier = await APIInstance.get(endpoints.finance.get, { headers: authHeader() });
        if(financier.data) {
            return financier.data;
        }
    } catch (error) {
        throw error;
    }
};

export const createFinance = async(data) => {
    try{
        const financier = await APIInstance.post(endpoints.finance.create, data, {headers: authHeader()});
        if(financier.data){
            return financier.data;
        }
    }
    catch (error) {
        throw error;
    }
};

export const updateFinance = async(data) => {
    try {
        const financier = await APIInstance.put(endpoints.finance.put + data.id,data, { headers: authHeader() });
        if(financier.data) {
            return financier.data;
        }
    } catch (error) {
        throw error;
    }
};

/*--------Mode de paiement----------*/
export const getAllMode = async() => {
    try{
        const mode_paiement = await APIInstance.get(endpoints.mode_paiement.get, { headers: authHeader() });
        if(mode_paiement.data){
            return mode_paiement.data;
        }
    }
    catch(error){
        throw error;
    }
};

/*--------Entreprise---Mode de paiement----------*/
export const getEntrepriseMode = async() => {
    try{
        const ent_mode = await APIInstance.get(endpoints.ent_mode_paiement.get, { headers: authHeader() });
        if(ent_mode.data){
            //console.log("MODE",ent_mode.data);
            return ent_mode.data;
        }
    }
    catch(error){
        throw error;
    }
};

export const createEntrepriseMode = async(data) => {
    try{
        const newEntMode = await APIInstance.post(endpoints.ent_mode_paiement.create, data, { headers: authHeader()});
        if(newEntMode.data){
            return newEntMode.data;
        }
    }
    catch(error){
        throw error;
    }
};

export const deleteEntrepriseMode = async(data) => {
    try{
        const deleteMode = await APIInstance.delete(endpoints.ent_mode_paiement.delete + data.id, { headers: authHeader()} );
        if(deleteMode.data){
            return deleteMode.data;
        }
    }
    catch(error){
        throw error;
    }
};