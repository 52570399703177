import authHeader from "../auth-header/auth-header";
import APIInstance from "../axios/AxiosInstance";
import { endpoints } from "../endpoints/endpoints"

export const getMyNumber = async () => {
   try {
      const number = await APIInstance.get(endpoints.numerotation.get, { headers: authHeader() })
      if (number.data) {
         return number.data
      }
   } catch (error) {
      throw error
   }
}

export const updateNumerotationApi = async (data) => {
   try {
      const number_auto = await APIInstance.put(endpoints.numerotation.put + data.id, data, { headers: authHeader() });
      if (number_auto.data) {
         return number_auto.data;
      }
   } catch (error) {
      throw error;
   }

}