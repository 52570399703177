import React from 'react'
import { useNavigate } from "react-router-dom";
import Modal from "../../library/Modal/Modal"
import { useState } from "react";
import { Button } from "react-bootstrap";


const Reservation = () => {
   const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate()

    const handleClick = () => {
        setOpenModal(true)
    }
    const handleCancel = () => {
        setOpenModal(false)
        navigate('/menu')
    }
   return (
      <div>
         <Modal clickAway={false} open={openModal} setOpen={setOpenModal}>
            <div className="max-w-[450px]">
               <p className="font-bold">
                  souhaitez vous sauvegarder
               </p>
               <div>
                  <Button variant="outline-primary" size="sm" onClick={handleCancel}>ok</Button>
                  <Button className="mx-2" variant="outline-danger" size="sm" onClick={handleCancel}>Annuler</Button>
               </div>
            </div>

         </Modal>
         <p onClick={handleClick}>Retour</p>
         <div>Reservation</div>
      </div>
   )
}

export default Reservation