import React, {useState, useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { Nav } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import {AiOutlinePlus, AiOutlineMinus} from 'react-icons/ai';
import './Financier.css';
import Modal from "../../../../library/Modal/Modal";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import LoadingSpinner from '../../../../library/Spinner/LoadingSpinner';
import { updateFinance, getAllMode, getEntrepriseMode, getMyFinance, createEntrepriseMode, deleteEntrepriseMode} from '../../../../api/financier/FinancierEndpoints';
import 'react-toastify/dist/ReactToastify.css';

const Financier = () => {
    const [financeData, setFinanceData] = useState({
        id:'',
        tva:'',
        devise:'',
        ca_journalier:'',
        ca_hebdomadaire:'',
        ca_mensuel:'',
        ca_trimestriel:'',
        ca_semestriel:'',
        ca_annuel:''
    });
    const [modePaiement, setModePaiement] = useState([]);
    const [modeEntreprise, setModeEntreprise] = useState([]);
    const [selectedValue, setSelectedValue] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isModified, setIsModified] = useState(false);
    const navigate = useNavigate();
    

    useEffect(() => {
        fetchFinanceData();
        fetchModePaiement();
        fetchModeEntreprise();
      }, []);


    const handleClick = () => {
        setOpenModal(true)
    };

    const handleCancel = () => {
        setOpenModal(false)
        navigate(-1)
    };

    const handleInputChange = event => {
        setFinanceData({ ...financeData, [event.target.name]: event.target.value });
        setSelectedValue(event.target.value);
        setIsModified(true);
    };

    const handleItemClick = (item) => {
        setSelectedItem(item);
    };

    const fetchFinanceData = async() => {
        try {
            setIsLoading(true);
            const data = await getMyFinance();
            setFinanceData(data);
        } 
        catch (error) {
           console.error(error);
         }
         finally {
            setIsLoading(false);
          }
    };

    const fetchModePaiement = async() => {
        try{
             setIsLoading(true);
            const allMode = await getAllMode();
            setModePaiement(allMode);
        }
        catch (error) {
            console.error(error);
          }
          finally {
            setIsLoading(false);
          }
    };

    const fetchModeEntreprise = async() => {
        try{
            setIsLoading(true);
            const ent_mode = await getEntrepriseMode();
            setModeEntreprise(ent_mode);
            console.log('MODE ENTREPRISE', ent_mode);
        }
        catch(error){
            console.error(error);
        }
        finally {
            setIsLoading(false);
          }
    };

    const modifyFinance = async () => {
        try {
          const finance = await updateFinance(financeData);
          setIsModified(false);
          if (finance.status === 200) {
            toast.success(finance.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            fetchFinanceData();
          } else {
            toast.error(finance.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
          }
        } catch (error) {
          console.error(error);
        }
      };

    const addMode = async() => {
        try{
            const user_data = localStorage.getItem("user_data");
            const data = JSON.parse(user_data);
            const id_entreprise = data.utilisateur.id_entreprise;
            await createEntrepriseMode({id_mode_paiement: selectedValue, id_entreprise});
            fetchModeEntreprise();
        }
        catch(error){
            console.error(error);
        }
        finally {
            setIsLoading(false);
          }
    };

    const deleteMode = async() => {
        try {
            await deleteEntrepriseMode({id: selectedItem});
            fetchModeEntreprise();
        }
        catch(error){
            console.error(error);
        }
        finally {
            setIsLoading(false);
          }
    };

    return (
        <Container style={{ marginTop: '10px' }}>
            <Row>
                <h5 className='text-primary'>Paramètres Financiers</h5>
                {isLoading ? (
                    <>
                        <LoadingSpinner />
                    </>
                ) : (
                    <>
                    <Col>
                    <Form.Control
                        type="hidden"
                        id="id"
                        name="id"
                        value={financeData.id}
                        onChange={handleInputChange}
                    />
                    <div>
                        <Form.Label htmlFor="devise">Paramétrage devise</Form.Label>
                        <Form.Control
                            type="text"
                            id="devise"
                            name="devise"
                            value={financeData.devise}
                            onChange={handleInputChange}
                            disabled
                        />
                    </div>
                    <div>
                        <Form.Label htmlFor="tva">Taux de TVA (%)</Form.Label>
                        <Form.Control
                            type="number"
                            id="tva"
                            name="tva"
                            value={financeData.tva}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <Form.Label htmlFor="mode_paiement">Mode de paiement</Form.Label>
                    </div>
                    <div>
                        <Card className='my-3 text-center' style={{ width: '25rem', height:'200px' }}>
                            <ListGroup variant="flush">
                                {modeEntreprise.map((mode_paie) => {
                                    return(
                                        <ListGroup.Item active={selectedItem===mode_paie.id} onClick={() => handleItemClick(mode_paie.id)}>{mode_paie.conf_mode_paiement.nom}</ListGroup.Item>
                                    )
                                })}
                            </ListGroup>
                        </Card>
                    </div>
                    <div className="d-flex flex-row mt-2" >
                        <Form.Select value={selectedValue} onChange={handleInputChange}>
                            {modePaiement.map((mode) => {
                                return(
                                    <option value={mode.id}>{mode.nom}</option>
                                )
                            })}
                        </Form.Select>
                        <div className="d-flex flex-row mx-2 mt-2">
                            <AiOutlinePlus size={25} onClick={addMode}/>
                            <AiOutlineMinus size={25} onClick={deleteMode}/>
                        </div> 
                    </div>  
                </Col>
                <Col>
                    <div>
                        <Form.Label htmlFor="ca_journalier">CA Journalier</Form.Label>
                        <Form.Control
                            type="number"
                            id="ca_journalier"
                            name="ca_journalier"
                            value={financeData.ca_journalier}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <Form.Label htmlFor="ca_hebdomadaire">CA Hebdomadaire</Form.Label>
                        <Form.Control
                            type="number"
                            id="ca_hebdomadaire"
                            name="ca_hebdomadaire"
                            value={financeData.ca_hebdomadaire}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <Form.Label htmlFor="ca_mensuel">CA Mensuel</Form.Label>
                        <Form.Control
                            type="number"
                            id="ca_mensuel"
                            name="ca_mensuel"
                            value={financeData.ca_mensuel}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <Form.Label htmlFor="ca_trimestriel">CA Trimestriel</Form.Label>
                        <Form.Control
                            type="number"
                            id="ca_trimestriel"
                            name="ca_trimestriel"
                            value={financeData.ca_trimestriel}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <Form.Label htmlFor="ca_semestriel">CA Semestriel</Form.Label>
                        <Form.Control
                            type="number"
                            id="ca_semestriel"
                            name="ca_semestriel"
                            value={financeData.ca_semestriel}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <Form.Label htmlFor="ca_annuel">CA Annuel</Form.Label>
                        <Form.Control
                            type="number"
                            id="ca_annuel"
                            name="ca_annuel"
                            value={financeData.ca_annuel}
                            onChange={handleInputChange}
                        />
                    </div>
                </Col>
                <div className="mt-3">
                <Button variant="success" className="my-2" onClick={modifyFinance} disabled={!isModified}>
                    <Nav.Link href='#' >Enregistrer</Nav.Link>
                </Button>
                <Button variant="secondary" className="my-2 mx-3" onClick={handleClick}>
                    <Nav.Link href='#' >Retour</Nav.Link>
                </Button>
                <Modal clickAway={false} open={openModal} setOpen={setOpenModal}>
                    <div className="max-w-[450px]">
                        <p className="font-bold">
                            Souhaitez vous sauvegarder?
                        </p>
                        <div>
                            <Button variant="outline-primary" size="sm" onClick={handleCancel}>ok</Button>
                            <Button className="mx-2" variant="outline-danger" size="sm" onClick={handleCancel}>Annuler</Button>
                        </div>
                    </div>
                </Modal>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </div>
                </>
                )}    
            </Row>
            
        </Container>
    )
}

export default Financier;