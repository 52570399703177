export default function authHeader() {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data);
    let token = data.access_token;
    // console.log("token ", token);
    // const config = {
    //   "Content-Type": "multipart/form-data",
    //   "Accept": "application/json",
    //   "type": "formData"
    // }
  
    if (token) {
      return { Authorization: 'Bearer ' + token }; // for Spring Boot back-end
      // return { 'x-access-token': token.accessToken };       // for Node.js Express back-end
    } else {
      return { Authorization: '' }; // for Spring Boot back-end
      // return { 'x-access-token': null }; // for Node Express back-end
    }
  }
  