import authHeader from "../auth-header/auth-header";
import  APIInstance from "../axios/AxiosInstance";
import { endpoints } from "../endpoints/endpoints";

export const Switch = async(data) => {
    try {
        const users = await APIInstance.put(endpoints.switch.update, data, { headers: authHeader() });
        if(users.data) {
            return users.data;
        }
    } catch (error) {
        throw error;
    }
}