import authHeader from "../auth-header/auth-header";
import  APIInstance from "../axios/AxiosInstance";
import { endpoints } from "../endpoints/endpoints";

export const updateSalle = async(data) => {
    try {
        const salle = await APIInstance.put(endpoints.salle.update, data, { headers: authHeader() });
        if(salle.data) {
            return salle.data;
        }
    } catch (error) {
        return error.response.data;
    }
}