import React, { useEffect } from 'react'
import { AiOutlineMenu } from "react-icons/ai"
import "./navbar.css"
import { useStateContext } from '../../context/user/ContextProvider';
import { Image } from 'react-bootstrap';
import Avantars from "../../image/user.jpg"

const Navbar = () => {
   const { setActiveMenu, screenSize, setScreenSize } = useStateContext();

   useEffect(() => {
      const handleScreenSize = () => setScreenSize(window.innerWidth);
      window.addEventListener('resize', handleScreenSize);
      handleScreenSize();
      return () => window.removeEventListener('resize', handleScreenSize)
   }, []);

   useEffect(() => {
      if (screenSize <= 900) {
         setActiveMenu(false)
      } else {
         setActiveMenu(true)
      }
   }, [screenSize])


   return (
      <div className="navbar">
         <div>
            <span>
               <AiOutlineMenu onClick={() => setActiveMenu((prevMenu) => !prevMenu)} className="icon-app" />
            </span>
         </div>
         <div>
         <span>
               Nadiasina Nico
            </span>
            <Image className='mx-2' style={{ borderRadius: "50%" }} width={40} height={40} src={Avantars} circle />
            
         </div>

      </div>
   )
}

export default Navbar