import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en.json';
import frTranslation from './locales/fr.json';

const resources = {
    en: {
      translation: enTranslation
    },
    fr: {
      translation: frTranslation
    }
  };

i18n.use(initReactI18next).init({
  // Configuration de base
  resources,
  lng: 'fr', // Langue par défaut
  fallbackLng: 'en', // Langue de secours en cas de traduction manquante
  interpolation: {
    escapeValue: false, // Pas besoin d'échapper les caractères HTML
  },
});

export default i18n;
