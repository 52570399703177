import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card';
import "./menu.css"
import { Container, Button } from 'react-bootstrap';
import { data } from '../utils/data';
import { NavLink } from 'react-router-dom';
import Modal from '../../library/Modal/Modal';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import {Switch} from '../../api/switch/Switch';

const Menu = () => {
   const [openModal, setOpenModal] = useState(false);
   const { t, i18n } = useTranslation();
  
   const [selectedValue, setSelectedValue] = useState("fr");
   const changeLanguage = (event) => {
      const value = event.target.value;
      localStorage.setItem('lg_user',value);
      setSelectedValue(value);
      i18n.changeLanguage(value);
   };

   useEffect(() => {
      window.history.pushState(null, null, window.location.href);
      console.log(localStorage.getItem("lg_user"));
      i18n.changeLanguage(localStorage.getItem("lg_user"));
      setSelectedValue(localStorage.getItem("lg_user"));
   }, []);

   const handleClick = () => {
      setOpenModal(true);
   };
   const handleCancel = () => {
      setOpenModal(false);
   };
   const handleLogOut = async () => {
      const lg_change = localStorage.getItem("lg_user");
      const user_data =  localStorage.getItem("user_data");
      const data = JSON.parse(user_data);
      let lg_user = data.utilisateur.langue;
      if(lg_change !==  lg_user){
         console.log("lg_change", lg_change);
         console.log("lg_user", lg_user);
         await Switch({id : data.utilisateur.id, langue : lg_change});
         setOpenModal(false);
         localStorage.clear();
         //navigate("/");
         window.location.href = '/'; 
      }

      setOpenModal(false);
      localStorage.clear();
      //navigate("/");
      window.location.href = '/'; 
   };

   return (

      <Container>
         <div className='mt-2'>
            <div className='d-flex justify-content-end'>
               <div className='mx-3'>
                  <Button variant="outline-danger" onClick={handleClick}>{t('deconnecter')}</Button>
               </div>
               <div>
                  <Form.Select value={selectedValue} onChange={changeLanguage} >
                     <option value="en">en</option>
                     <option value="fr">fr</option>
                  </Form.Select>
               </div>
            </div>
            <Modal clickAway={false} open={openModal} setOpen={setOpenModal}>
               <div className='max-w-[450px]'>
                  <p className='font-bold'>
                     {t('message_deconnexion')}?
                  </p>
                  <div>
                     <Button variant='outline-primary' size="sm" onClick={handleLogOut}>{t('oui')}</Button>
                     <Button className='mx-2' variant='outline-danger' size="sm" onClick={handleCancel}>{t('non')}</Button>
                  </div>
               </div>
            </Modal>
         </div>
         <div className='menu-app  mt-5'>
            {data.map((menu) => {
               return (
                  <Card className='my-3 text-center' style={{ width: '18rem' }} key={menu.id}>
                     <Card.Body>
                     <Card.Title className="text-warning" style={{ fontSize: "2.5rem" }} >{menu.icon}</Card.Title>
                       
                        <NavLink to={menu.link} className='text-secondary my-3 nav-link' >{t(menu.title)}
                        </ NavLink>
                     </Card.Body>
                  </Card>
               )
            })}
         </div>

      </Container>
   )
}

export default Menu;