import React,{useEffect,useState} from 'react'
import Form from 'react-bootstrap/Form';
import { Nav } from "react-bootstrap"
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getmycompany, modifymycompany } from '../../../../api/company/CompanyEndpoint';
import Alert from 'react-bootstrap/Alert';
import LoadingSpinner from '../../../../library/Spinner/LoadingSpinner';
import Modal from "../../../../library/Modal/Modal";
import { useNavigate } from "react-router-dom";

const Company = () => {

   const [companyData, setCompanyData] = useState({
      id: '',
      raison_sociale: '',
      adresse: '',
      ville: '',
      code_postal: '',
      telephone1: '',
      telephone2: '',
      email: '',
      nif: '',
      statut: ''
    });

    const [isModified, setIsModified] = useState(false);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
      setOpenModal(true)
  }
  const handleCancel = () => {
      setOpenModal(false)
      navigate(-1)
  }

    useEffect(() => {
      fetchCompanyData();
    }, []);

    const fetchCompanyData = async () => {
      try {
         setIsLoading(true);
        const data = await getmycompany();
        setCompanyData(data);
        console.log('COMPANY', data);
      } catch (error) {
        setError('Erreur lors de la modification des données de l\'entreprise.');
        if (error.response && error.response.data && error.response.data.error) {
         setError(error.response.data.error.join(' '));
       } else {
         setError('Erreur lors du chargement des données de l\'entreprise.');
       }
      }  finally {
         setIsLoading(false);
       }
    };

    const handleModify = async () => {
      try {
         setIsLoading(true);
         const updatedData = await modifymycompany(companyData);
         // Traitement des données mises à jour si nécessaire
         setError(null);
         setIsModified(false);
       } catch (error) {
         setError('Erreur lors de la modification des données de l\'entreprise.');
         if (error.response && error.response.data && error.response.data.error) {
            setError(error.response.data.error.join(' '));
          } else {
            setError('Erreur lors de la modification des données de l\'entreprise.');
          }
       }  finally {
         setIsLoading(false);
       }
    };

    const handleInputChange = event => {
      setCompanyData({ ...companyData, [event.target.name]: event.target.value });
      setIsModified(true); // Définir l'état de modification sur true lorsqu'un champ est modifié
    };

  return (
      <Container style={{ marginTop: '10px' }}>
      
      <h5 className='text-primary'>Entreprise </h5>

      {isLoading ? (
         <>
        <LoadingSpinner/> 
        </>
      ) : (
         <>
         <Row>
      {error && <Alert key="danger" variant="danger">{error}</Alert>}
         <Col>
         <Form.Control
               type="hidden"
               id="id"
               name="id"
               value={companyData.id}
               onChange={handleInputChange}
            />
         <div>
            <Form.Label htmlFor="raison_sociale">Raison sociale</Form.Label>
            <Form.Control
               type="text"
               id="raison_sociale"
               name="raison_sociale"
               value={companyData.raison_sociale}
               onChange={handleInputChange}
            />
         </div>
         <div>
            <Form.Label htmlFor="adresse">Adresse</Form.Label>
            <Form.Control
               type="text"
               id="adresse"
               name = "adresse"
               value={companyData.adresse}
               onChange={handleInputChange}
            />
         </div>
         <div>
            <Form.Label htmlFor="ville">Ville</Form.Label>
            <Form.Control
               type="text"
               id="ville"
               name="ville"
               value={companyData.ville}
               onChange={handleInputChange}
            />
         </div>
         <div>
            <Form.Label htmlFor="code_postal">Code postal</Form.Label>
            <Form.Control
               type="number"
               id="code_postal"
               name="code_postal"
               value={companyData.code_postal}
               onChange={handleInputChange}
            />
         </div>
         <div>
            <Form.Label htmlFor="telephone1">Telephone 1</Form.Label>
            <Form.Control
               type="tel"
               id="telephone1"
               name="telephone1"
               value={companyData.telephone1}
               onChange={handleInputChange}
            />
         </div>
         </Col>
         <Col>
         <div>
            <Form.Label htmlFor="telephone2">Telephone 2</Form.Label>
            <Form.Control
               type="tel"
               id="telephone2"
               name="telephone2"
               value={companyData.telephone2}
               onChange={handleInputChange}
            />
         </div>
         <div>
            <Form.Label htmlFor="email">Email</Form.Label>
            <Form.Control
               type="email"
               id="email"
               name="email"
               value={companyData.email}
               onChange={handleInputChange}
            />
         </div>
         <div>
            <Form.Label htmlFor="nif">NIF</Form.Label>
            <Form.Control
               type="number"
               id="nif"
               name="nif"
               value={companyData.nif}
               onChange={handleInputChange}
            />
         </div>
         <div>
            <Form.Label htmlFor="statut">STAT</Form.Label>
            <Form.Control
               type="number"
               id="statut"
               name="statut"
               value={companyData.statut}
               onChange={handleInputChange}
            />
         </div>
   </Col>
   </Row>
   <Row>
      <Col>
      <Button variant="success" className="my-2" onClick={handleModify} disabled={!isModified}>
         <Nav.Link href='#' >
         Modifier</Nav.Link>
      </Button>
      <Button variant="outline-secondary" className="mx-2"> <Nav.Link href='#' onClick={handleClick}>
         Retour</Nav.Link></Button>
         <Modal clickAway={false} open={openModal} setOpen={setOpenModal}>
                <div className="max-w-[450px]">
                    <p className="font-bold">
                        souhaitez vous sauvegarder
                    </p>
                    <div>
                        <Button variant="outline-primary" size="sm" onClick={handleCancel}>ok</Button>
                        <Button className="mx-2" variant="outline-danger" size="sm" onClick={handleCancel}>Annuler</Button>
                    </div>
                </div>

            </Modal>
      </Col>
      <Col>
      </Col>
      <Col>
      </Col>
   
   </Row>
   </>
   )}
</Container>
  )
}

export default Company