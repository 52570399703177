import React, { useState, createContext, useContext } from 'react'

const StateContext = createContext()

export const ContextProvider = ({ children }) => {
   const [activeMenu, setActiveMenu] = useState(true)
   const [screenSize, setScreenSize] = useState(undefined)
   return (
      <StateContext.Provider value={{
         activeMenu,
         screenSize,
         setActiveMenu,
         setScreenSize
      }}>
         {children}

      </StateContext.Provider>
   )

}

export const useStateContext = () => useContext(StateContext)