import React from 'react'

const Stock = () => {
  return (
    <div className='m-4'>
             <h3 className='text-danger'>Stock</h3>
        </div>
  )
}

export default Stock