import React, { useState } from 'react'
import { Collapse } from 'react-bootstrap'
import { NavLink } from "react-router-dom"
import { BsChevronRight } from 'react-icons/bs';

const MenuItems = ({ items }) => {
   const [open, setOpen] = useState(false);
   return (
      <>
         {items.submenu ? (
            <>
               <NavLink
                     style={{ border: "none", backgroundColor: "#151529", margin: "1.7rem 0.5rem" }}
                     className="d-block w-100 text-secondary  nav-link"
                     onClick={() => setOpen(!open)}
                     aria-controls="example-collapse-text"
                     aria-expanded={open}
               >
                  <span className='mx-2' style={{ fontSize: "1.2rem" }}>{items.icon}</span>{items.title} <span className='mx-4'> <BsChevronRight /> </span> 
               </NavLink>
               {items.submenu.map((submenu, index) => (
                  <>
                     <Collapse in={open} >
                        <NavLink to={submenu.link} id="example-collapse-text " style={{ margin: "1.7rem 0.5rem" }} className="nav-link my-3 text-secondary">
                           <span className='mx-2' style={{ fontSize: "1.2rem" }}>{submenu.icon}</span>{submenu.title}
                        </NavLink>
                     </Collapse>
                  </>
               ))}
            </>
         ) : (
            <NavLink to={items.link} className='nav-link text-secondary' style={{ margin: "1.7rem 0.5rem" }}>
               <span className='mx-2' style={{ fontSize: "1.2rem" }}>{items.icon}</span>{items.title}
            </NavLink>
         )
         }
      </>
   )
}

export default MenuItems