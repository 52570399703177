export const endpoints = {
   utilisateur: {
      login: "/utilisateurs/login",
      create: "/user/create"
   },

   client: {
      create: "/create",
      update: "/client/update",
      search: '/client/search'
   },
   file: "/upload/file",
   switch: {
      update: "/language/switch",
   },
   salle : {
      create : "/salle/create",
      gets : "/salle",
      update : "/salle/update",
      get : "/salle"
   },
   company: {
      get: 'entreprises/me',
      put: 'entreprises/'
   },
   numerotation: {
      put: "numerotation/update/",
      get:"/numerotation/myNumber"
   },
   choixCode: {
      put: "numerotation/choix/",
      get:"numerotation/myChoix"
   },
   finance: {
      get: '/parametre-financier/my-finance',
      create: '/parametre-financier/create-finance',
      put: '/parametre-financier/finance/'
   },
   mode_paiement: {
      get: '/mode-paiement',
      getById : '/mode-paiement/'
   },
   ent_mode_paiement:{
      get: '/entreprise-mode-paiement/mode-paiement',
      create: '/entreprise-mode-paiement/create-mode-paiement',
      delete:'/entreprise-mode-paiement/'
   }
}
