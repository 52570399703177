import  APIInstance from "../axios/AxiosInstance"
import { endpoints } from "../endpoints/endpoints"

export const LogUser = async (data) => {
    try {
        const users = await APIInstance.post(endpoints.utilisateur.login, data);
        if(users.data) {
          localStorage.setItem('user_data', JSON.stringify(users.data));
          // localStorage.setItem('user_token', users.headears["authorization"]);
          return users.data;
        }
      } 
      catch (error) {
        throw error;
      }
 }