import Sidebar from "../Sidebar/sidebar";
import { demarerLink } from "../utils/demarerLink";

const Demarrer = ({children}) => {


    return (
        <div className="d-flex">
            <div className='app-sidebars'>
                <Sidebar  titre="Demarer" link={demarerLink}/>
            </div>
            <div className='app-sidebar'>
                {children}
            </div>
        </div>

    );
}

export default Demarrer;