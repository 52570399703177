import React from 'react'
import { Navigate, Outlet, useLocation} from "react-router-dom"

const RequireAuth = () => {
   const users = localStorage.getItem('user_data');
   const location = useLocation();
  return (
    users ? <Outlet /> : <Navigate  to="/" state={{ from: location }} replace />
  )
}

export default RequireAuth