import React, { useEffect, useState } from 'react'
import { Alert, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { getMyNumber, updateNumerotationApi } from '../../../../api/numerotation/numerotation';
import LoadingSpinner from '../../../../library/Spinner/LoadingSpinner';
import ChoixCode from './ChoixCode/ChoixCode';
import { useNavigate } from 'react-router-dom';
import Modal from '../../../../library/Modal/Modal';


const Numerotation = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [error, setError] = useState(null);
   const [isUpdate, setIsUpdate] = useState(false);
   const [openModal, setOpenModal] = useState(false);
   const navigate = useNavigate();
   const [values, setValues] = useState({
      id: '',
      prefixe_caisse: "",
      prefixe_ticket_caisse: "",
      prefixe_facture: "",
      prefixe_note_credit: "",
      prefixe_devis: "",
      prefixe_cmd_client: "",
      prefixe_bon_livraison: "",
      prefixe_fabrication_cmd: "",
      prefixe_cmd_fournisseur: "",
      statut: ''
   })


   useEffect(() => {
      numberDataApi()
   }, []);
   const numberDataApi = async () => {
      try {
         setIsLoading(true);
         const data = await getMyNumber()
         setValues(data);
         setIsLoading(false);
      } catch (error) {
         console.log(error)
      }
   }

   const handleChange = (e) => {
      e.preventDefault();
      const { name, value } = e.target;
      setValues((prev) => ({
         ...prev,
         [name]: value
      }))
      setIsUpdate(true)
   }
   const handleModify = async () => {
      try {
         setIsLoading(true);
         const update = await updateNumerotationApi(values)
         setIsLoading(false);
         setIsUpdate(false)
      } catch (error) {
         console.log(error)
      }

   }
   const handleClick = () => {
      setOpenModal(true)
   }
   const handleCancel = () => {
      setOpenModal(false)
      navigate(-1)
   }
   return (
      <div class="container">
         <h3 className='text-warning mt-4'>Numèrotation </h3>

         {isLoading ? (
            <>
               <LoadingSpinner />
            </>
         ) : (
            <>
               {error && <Alert key="danger" variant="danger">{error}</Alert>}
               <h6 className='text-secondary   my-4'>Numèrotation automatique des nouvelles fiches </h6>
               <ChoixCode />
               <hr />
               <h6 className='text-secondary mt-4'>Numèros des documents</h6>
               <div className='d-flex mt-2'>
                  <div>
                     <Form.Label htmlFor="inputPassword5">Tickets de caisse</Form.Label>
                     <Form.Control
                        type="text"
                        name="prefixe_caisse"
                        value={values.prefixe_caisse}
                        onChange={handleChange}

                     />
                  </div>
                  <div className='mx-2'>
                     <Form.Label htmlFor="inputPassword5">Factures</Form.Label>
                     <Form.Control
                        type="text"
                        name="prefixe_facture"
                        value={values.prefixe_facture}
                        onChange={handleChange}

                     />
                  </div>
                  <div>
                     <Form.Label htmlFor="inputPassword5">Notes de credit (avoir)</Form.Label>
                     <Form.Control
                        type="text"
                        name="prefixe_note_credit"
                        value={values.prefixe_note_credit}
                        onChange={handleChange}
                     />
                  </div><div className='mx-2'>
                     <Form.Label htmlFor="inputPassword5">Devis</Form.Label>
                     <Form.Control
                        type="text"
                        name="prefixe_devis"
                        value={values.prefixe_devis}
                        onChange={handleChange}
                     />
                  </div>
               </div>
               <div className='d-flex mt-4'>
                  <div  >
                     <Form.Label htmlFor="inputPassword5">Commandes client</Form.Label>
                     <Form.Control
                        type="text"
                        name="prefixe_cmd_client"
                        value={values.prefixe_cmd_client}
                        onChange={handleChange}
                     />
                  </div><div className='mx-2'>
                     <Form.Label htmlFor="inputPassword5">Bons de livraison</Form.Label>
                     <Form.Control
                        type="text"
                        name="prefixe_bon_livraison"
                        value={values.prefixe_bon_livraison}
                        onChange={handleChange}

                     />
                  </div>
                  <div className='mx-2'>
                     <Form.Label htmlFor="inputPassword5">Commandes fournisseurs</Form.Label>
                     <Form.Control
                        type="text"
                        name="prefixe_cmd_fournisseur"
                        value={values.prefixe_cmd_fournisseur}
                        onChange={handleChange}
                     />
                  </div>
                  <div>
                     <Form.Label htmlFor="inputPassword5">Fabrication commande</Form.Label>
                     <Form.Control
                        type="text"
                        name="prefixe_fabrication_cmd"
                        value={values.prefixe_fabrication_cmd}
                        onChange={handleChange}
                     />
                  </div>
               </div>
               <p className='text-danger my-3'>Ne pas changer la Numerotaion durant l'anné sous peines d'erreurs</p>
               <p className='my-3'>La numérotaion peut-être changée lors d'une nouvelle année ou lors de l'initialisation du dossier courant</p>
               <div className='my-3'>
                  <Button variant="outline-info" size="md" onClick={handleModify} disabled={!isUpdate}>Enregistrer</Button>
                  <Button variant="outline-secondary" className="mx-2" onClick={handleClick}> Retour</Button>
                  <Modal clickAway={false} open={openModal} setOpen={setOpenModal}>
                     <div className="max-w-[450px]">
                        <p className="font-bold">
                           souhaitez vous sauvegarder
                        </p>
                        <div>
                           <Button variant="outline-primary" size="sm" onClick={handleCancel}>ok</Button>
                           <Button className="mx-2" variant="outline-danger" size="sm" onClick={handleCancel}>Annuler</Button>
                        </div>
                     </div>

                  </Modal>
               </div>
            </>
         )}

      </div>
   )
}

export default Numerotation