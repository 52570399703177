import axios from "axios";

let BASE_URL_API;

if (process.env.NODE_ENV === "production") {
  BASE_URL_API = process.env.REACT_APP_PROD_API;
} else {
  BASE_URL_API = process.env.REACT_APP_DEV_API;
}

console.log(BASE_URL_API);
const APIInstance = axios.create({
  baseURL: BASE_URL_API,
  timeout: 5000,
});

export default APIInstance;