import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import './fournisseur.css';

const Fournisseur = () => {
    return (
        <div className='m-4'>
            <h3 className='text-danger   '>Fournisseur </h3>
            <div className='form-app'>
                <div>
                    <div>
                        <Form.Label htmlFor="inputPassword5">Code</Form.Label>
                        <Form.Control
                            type="text"
                            id="inputPassword5"
                            aria-describedby="passwordHelpBlock"
                        />
                    </div>
                    <div>
                        <Form.Label htmlFor="inputPassword5">Donomination</Form.Label>
                        <Form.Control
                            type="text"
                            id="inputPassword5"
                            aria-describedby="passwordHelpBlock"
                        />
                    </div>
                    <div>
                        <Form.Label htmlFor="inputPassword5">Nom</Form.Label>
                        <Form.Control
                            type="text"
                            id="inputPassword5"
                            aria-describedby="passwordHelpBlock"
                        />
                    </div>
                    <div>
                        <Form.Label htmlFor="inputPassword5">Prenom</Form.Label>
                        <Form.Control
                            type="text"
                            id="inputPassword5"
                            aria-describedby="passwordHelpBlock"
                        />
                    </div>
                    <div>
                        <Form.Label htmlFor="inputPassword5">Ca</Form.Label>
                        <Form.Control
                            type="text"
                            id="inputPassword5"
                            aria-describedby="passwordHelpBlock"
                        />
                    </div>
                    <div>
                        <Form.Label htmlFor="inputPassword5">TVA</Form.Label>
                        <Form.Control
                            type="number"
                            id="inputPassword5"
                            aria-describedby="passwordHelpBlock"
                            min="1"
                        />
                    </div>
                    <div>
                        <Form.Label htmlFor="inputPassword5">Remise</Form.Label>
                        <Form.Control
                            type="number"
                            id="inputPassword5"
                            aria-describedby="passwordHelpBlock"
                            min="1"
                        />
                    </div>

                </div>
                <div className='mx-4'>
                    <div>
                        <Form.Label htmlFor="inputPassword5">Telephone 1</Form.Label>
                        <Form.Control
                            type="text"
                            id="inputPassword5"
                            aria-describedby="passwordHelpBlock"
                        />
                    </div>
                    <div>
                        <Form.Label htmlFor="inputPassword5">Telephone 2</Form.Label>
                        <Form.Control
                            type="text"
                            id="inputPassword5"
                            aria-describedby="passwordHelpBlock"
                        />
                    </div>
                    <div>
                        <Form.Label htmlFor="inputPassword5">Email</Form.Label>
                        <Form.Control
                            type="email"
                            id="inputPassword5"
                            aria-describedby="passwordHelpBlock"
                        />
                    </div>
                    <div>
                        <Form.Label htmlFor="inputPassword5">Mot de passe</Form.Label>
                        <Form.Control
                            type="password"
                            id="inputPassword5"
                            aria-describedby="passwordHelpBlock"
                        />
                    </div>
                    <div>
                        <Form.Label htmlFor="inputPassword5">Adresse</Form.Label>
                        <Form.Control
                            type="text"
                            id="inputPassword5"
                            aria-describedby="passwordHelpBlock"
                        />
                    </div>
                    <div>
                        <Form.Label htmlFor="inputPassword5">Ville</Form.Label>
                        <Form.Control
                            type="text"
                            id="inputPassword5"
                            aria-describedby="passwordHelpBlock"
                        />
                    </div>
                    <div>
                        <Form.Label htmlFor="inputPassword5">Code Postal</Form.Label>
                        <Form.Control
                            type="text"
                            id="inputPassword5"
                            aria-describedby="passwordHelpBlock"
                        />
                    </div>
                </div>

            </div>
            <Button variant="outline-primary" className="my-2">
                Enregistrer
            </Button>
        </div>
    )
}

export default Fournisseur;