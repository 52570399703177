import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { createSalle } from "../../../api/salle/createSalle";
import { getSalle } from '../../../api/salle/getSalle';
import { updateSalle } from '../../../api/salle/updateSalle';
import Spinner from 'react-bootstrap/Spinner';
import 'react-toastify/dist/ReactToastify.css';
import { toast_error, toast_success } from '../../../library/Toast/toast';
import ToastInstance from '../../../library/Toast/TostInstance';

const SalleForm = ({ openModal, closeModal, sizeModal, salleId }) => {
    const [nomSalle, setNomSalle] = useState('');
    const [loading, setLoading] = useState(true);
    const handleChangeNomSalle = (event) => {
        setNomSalle(event.target.value);
    };

    const getSallleByID = async (id) => {
        const salle = await getSalle(id);
        setNomSalle(salle.nom);
    };

    const initialisation = (id) => {
        if (id !== 0) {
            getSallleByID(id);
        }
    }

    useEffect(() => {
        initialisation(salleId);
    }, [salleId]);


    const ajoutSalle = async () => {
        const user_data = localStorage.getItem("user_data");
        const data = JSON.parse(user_data);
        const id_entreprise = data.utilisateur.id_entreprise;
        setLoading(false);
        if (salleId !== 0) {
            const valiny = await updateSalle({ id: salleId, nom: nomSalle });
            if (valiny) {
                if (valiny.salle) {
                    toast_success(valiny.message);
                } else {
                    toast_error(valiny.message_error);
                }
            }
        } else {
            const response = await createSalle({ id_entreprise: id_entreprise, nom: nomSalle });
            if (response) {
                if (response.salle) {
                    toast_success(response.message);
                } else {
                    toast_error(response.message_error);
                }
            }
        }
        setLoading(true);
        setNomSalle("");
        closeModal();
    }

    const annulation = () => {
        setNomSalle("");
        closeModal();
    }



    return (
        <>
            <Modal show={openModal} onHide={closeModal} size={sizeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{salleId === 0 ? "Ajout Salle" : "Modifier Salle"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Nom Salle</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder=""
                                onChange={handleChangeNomSalle}
                                value={nomSalle}
                                autoFocus
                                required
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={annulation}>Annuler</Button>
                    {
                        loading ?
                            <Button variant="outline-warning" onClick={ajoutSalle}>Enregistrer</Button>
                            :
                            <Button variant="outline-warning" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Button>
                    }


                </Modal.Footer>
            </Modal>
            <ToastInstance/>
        </>
    );

}

export default SalleForm;