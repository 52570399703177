import React from 'react';
import { ReactCalculator } from "simple-react-calculator";

const Calculatrice = () => {
    return (
        <>
            <ReactCalculator   />;
        </>
    );
};

export default Calculatrice;