

const Borne = () => {
    return (
        <div className='m-4'>
              <h5 className='text-primary'>Borne</h5>
        </div>
    );
}

export default Borne;