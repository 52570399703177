import React from 'react'

import './user.css'
import { Nav } from "react-bootstrap"
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const User = () => {
   return (
      <div className='m-4'>
         <h3 className='text-danger   '>Utilisateurs </h3>
         <div className='form-app '>
            <div>
               <div>
                  <Form.Label htmlFor="inputPassword5">Nom</Form.Label>
                  <Form.Control
                     type="email"
                     
                     id="inputPassword5"
                     aria-describedby="passwordHelpBlock"
                  />
               </div>
               <div>
                  <Form.Label htmlFor="inputPassword5">Adresse</Form.Label>
                  <Form.Control
                     type="password"
                     id="inputPassword5"
                     aria-describedby="passwordHelpBlock"
                  />
               </div>
               <div>
                  <Form.Label htmlFor="inputPassword5">Ville</Form.Label>
                  <Form.Control
                     type="password"
                     id="inputPassword5"
                     aria-describedby="passwordHelpBlock"
                  />
               </div>
               <div>
                  <Form.Label htmlFor="inputPassword5">Code postal</Form.Label>
                  <Form.Control
                     type="password"
                     id="inputPassword5"
                     aria-describedby="passwordHelpBlock"
                  />
               </div>
               <div>
                  <Form.Label htmlFor="inputPassword5">Tel</Form.Label>
                  <Form.Control
                     type="password"
                     id="inputPassword5"
                     aria-describedby="passwordHelpBlock"
                  />
               </div>
               <div>
                  <Form.Label htmlFor="inputPassword5">E-Mail</Form.Label>
                  <Form.Control
                     type="password"
                     id="inputPassword5"
                     aria-describedby="passwordHelpBlock"
                  />
               </div>
            </div>
            <div className='mx-4'>
               <div>
                  <Form.Label htmlFor="inputPassword5">N TVA</Form.Label>
                  <Form.Control
                     type="email"
                     id="inputPassword5"
                     aria-describedby="passwordHelpBlock"
                  />
               </div>
               <div>
                  <Form.Label htmlFor="inputPassword5">Banque</Form.Label>
                  <Form.Control
                     type="password"
                     id="inputPassword5"
                     aria-describedby="passwordHelpBlock"
                  />
               </div>
               <div>
                  <Form.Label htmlFor="inputPassword5">Acien Mot de passe</Form.Label>
                  <Form.Control
                     type="password"
                     id="inputPassword5"
                     aria-describedby="passwordHelpBlock"
                  />
               </div>
               <div>
                  <Form.Label htmlFor="inputPassword5">Mot de passe</Form.Label>
                  <Form.Control
                     type="password"
                     id="inputPassword5"
                     aria-describedby="passwordHelpBlock"
                  />
               </div>
               <div>
                  <Form.Label htmlFor="inputPassword5">Confirmez votre mot de passe</Form.Label>
                  <Form.Control
                     type="password"
                     id="inputPassword5"
                     aria-describedby="passwordHelpBlock"
                  />
               </div>
               <div>
                  <Form.Label htmlFor="inputPassword5">Chiffre d'affaire</Form.Label>
                  <Form.Control
                     type="password"
                     id="inputPassword5"
                     aria-describedby="passwordHelpBlock"
                  />
               </div>
            </div>
            
         </div>
         <Button variant="outline-primary" className="my-2">
               <Nav.Link href='' >

                  Connection</Nav.Link>
            </Button>
            {/* <Button variant="outline-secondary" className="mx-2"> <Nav.Link href='/inscrire' >

               Inscrire</Nav.Link></Button> */}
      </div>
   )
}

export default User