import { useState } from 'react';
import './login.css';
import { Image, Nav } from "react-bootstrap"
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { LogUser } from '../../api/utilisateur/LogUser';
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import DragonRoyal from '../../image/dragon-royal.jpg'
import LoadingSpinner from '../../library/Spinner/LoadingSpinner';

import Spinner from 'react-bootstrap/Spinner';


function Login() {
   const [email, setEmail] = useState('');
   const [mdp, setMdp] = useState('');
   const [validatedEmail, setValidatedEmail] = useState(true);
   const [validatedMdp, setValidatedMdp] = useState(true);
   const [errorEmail, setErrorEmail] = useState(false);
   const [errorMdp, setErrorMdp] = useState(false);
   const [showPassword, setShowPassword] = useState(false);
   const { t } = useTranslation();
   const [loading, setLoading] = useState(false);

   const validateLogin = async () => {
      try {
         if (!email) {
            setValidatedEmail(false);
            return null;
         }
         if (!mdp) {
            setValidatedMdp(false);
            return null;
         }
         setLoading(true);
         const user = await LogUser({ email, mdp });
         if (user.utilisateur) {
            localStorage.setItem('lg_user', user.utilisateur.langue);
            window.location.href = '/menu';
         }
      } catch (error) {
         if (error.response && error.response.status === 404) {
            setErrorEmail(true);
            setErrorMdp(false);
            setLoading(false);
         } else {
            setErrorEmail(false);
            setErrorMdp(true);
            setLoading(false);
         }
      }
   }

   return (
      <div className="login-app">
         <div className="login-app-dragon">
            <div className='app-login'>
               <Image src={DragonRoyal} width={450} />
            </div>
            <div className='login'>
               <h3 className=' mt-3 mb-4 title'>Bienvenue</h3>
               <div className='mt-5'>
                  <Form.Label htmlFor="inputPassword5" style={{
                     color: !validatedEmail && '#D32F2F',
                     fontWeight: !validatedEmail && 'bold',
                  }}>Adresse Email</Form.Label>
                  <Form.Control
                     type="email"
                     id="email"
                     aria-describedby="passwordHelpBlock"
                     defaultValue={email}
                     onChange={(e) => setEmail(e.target.value)}
                     style={{
                        border: !validatedEmail && '1px solid #D32F2F'
                     }}
                  />
                  {errorEmail &&
                     <p
                        id="EmailHelpBlock"
                        muted
                        style={{
                           fontSize: "10px",
                           color: "#D32F2F",
                           fontWeight: "bold"
                        }}
                     >
                        Utilisateur introuvable
                     </p>
                  }
               </div>
               <div className='my-3'>
                  <Form.Label htmlFor="inputPassword5"
                     style={{
                        color: !validatedMdp && '#D32F2F',
                        fontWeight: !validatedMdp && 'bold',
                     }}>{t('motdepasse')}</Form.Label>
                  <Form.Control
                     type={showPassword ? "text" : "password"}
                     id="password"
                     aria-describedby="passwordHelpBlock"
                     defaultValue={mdp}
                     onChange={(e) => setMdp(e.target.value)}
                     style={{
                        border: !validatedMdp && '1px solid #D32F2F'
                     }}
                  />
                  {
                     showPassword && (
                        <FaRegEyeSlash id="icon-eye" onClick={() => setShowPassword(!showPassword)} />
                     )
                  }
                  {
                     !showPassword && (
                        <FaRegEye id="icon-eye" onClick={() => setShowPassword(!showPassword)} />
                     )
                  }

                  {errorMdp &&
                     <p
                        id="EmailHelpBlock"
                        muted
                        style={{
                           fontSize: "10px",
                           color: "#D32F2F",
                           fontWeight: "bold"
                        }}
                     >
                        Mot de passe incorrect
                     </p>
                  }
               </div>
               <div className='btn-login-dragon'>
                  {loading ?  <Spinner animation="border" variant="warning" /> :  <Button variant="outline-warning" className=" w-100" onClick={validateLogin}>
                     <Nav.Link >{t('connexion')}</Nav.Link>
                  </Button>}
               </div>
               {/* <Button variant="outline-secondary" className="mx-2"> <Nav.Link href='/inscrire' >

               Inscrire</Nav.Link></Button> */}
            </div>
         </div>
      </div>
   );
}

export default Login;
