import authHeader from "../auth-header/auth-header";
import  APIInstance from "../axios/AxiosInstance";
import { endpoints } from "../endpoints/endpoints";

export const getSalle = async(id) => {
    try {
        const salle = await APIInstance.get(endpoints.salle.get+"/"+id, { headers: authHeader() });
        return salle.data;
    } catch (error) {
        throw error;
    }
}