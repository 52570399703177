import React from 'react';
import "./sidebar.css";
import Modal from '../../library/Modal/Modal';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Button } from "react-bootstrap";
import MenuItems from './MenuItems/MenuItems';

const Sidebar = ({ titre, link }) => {

   const [openModal, setOpenModal] = useState(false);
   const navigate = useNavigate()

   // const handleClick = () => {
   //    setOpenModal(true)
   // }
   const handleCancel = () => {
      setOpenModal(false)
      navigate('/menu')
   }

   return (
      <div className='sidebar-app'>
         <div className=' sidebar-nav'>

            <h5 className='p-3  text-white'>{titre}  </h5>
            <div className=' text-white p-2 '>
               {
                  link.map((param) => {
                     return (
                        <MenuItems items={param} />
                     )
                  })}

            </div>
         </div>

         <Modal clickAway={false} open={openModal} setOpen={setOpenModal}>
            <div className="max-w-[450px]">
               <p className="font-bold">
                  souhaitez vous sauvegarder
               </p>
               <div>
                  <Button variant="outline-primary" size="sm" onClick={handleCancel}>ok</Button>
                  <Button className="mx-2" variant="outline-danger" size="sm" onClick={handleCancel}>non</Button>
               </div>
            </div>

         </Modal>

      </div>
   )
}

export default Sidebar