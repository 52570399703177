import React from 'react';
import './loading.css';
import Spinner from 'react-bootstrap/Spinner';

const LoadingSpinner = () => {
  return (
    <>
    <div className="d-flex justify-content-center align-items-center others">
    <Spinner animation="border" variant="warning" />
    </div>  
    </>
  )
}

export default LoadingSpinner